
import { createApp } from 'vue'
import TourList from './components/TourList.vue'













createApp(TourList).mount('#TourList')

